@tailwind base;
@tailwind components;
@tailwind utilities;


/* Styles for the modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top */
  }
  
  /* Styles for the modal content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px; /* Adjust size */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  